.settings {
  .white-bg {
    background-color: #fff;
    border-radius: 10px;
    padding: 30px;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    font-family: "BW-modelica-medium";
    align-items: flex-start;
    .userwrap {
      width: 400px;
      height: 230px;
      margin-top: 20px;
      border: 1px dashed #ffcc22;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      margin-left: 20px;
      // cursor: pointer;
    }
  }

  .formidable {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;

    .switch-text {
      font-size: 16px;
      font-weight: 100;
      font-family: "BW-modelica-medium";
      margin-right: 100px;
    }
  }
}

.ant-switch {
  height: 17px;
  line-height: 17px;
}

.ant-switch-checked {
  background-color: #09427d;
}
.ant-switch-handle {
  top: -4px;
  left: -1px;
  height: 22px;
  width: 22px;
}
.ant-switch-handle::before {
  background-color: #f0f0f0;
}

.ant-tabs-tab-btn {
  color: #929292 !important;
  font-family: BW-modelica-medium !important;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #363636 !important;
  font-family: BW-modelica-medium !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}
