p{
    margin: 0;
    font-size: 17px;
}
.ant-modal-content{
    font-family: "BW-modelica-medium";
    padding: 30px 10px;
}

.ant-modal-title{
    color: #09427D;
    font-size: 20px !important;
    font-weight: 700;
}