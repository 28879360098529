.contact {
  .nav {
      display: flex;
      height: 90px;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0 170px;
      border-bottom: 1px solid rgba(212, 203, 203, 0.445);
      .logo {
        align-items: center;
        display: flex;
        
        a{
            align-items: center;
             display: flex;
        }
        img {
          width: 50px;
          margin-right: 5px;
        }
        span {
          font-size: 30px;
          font-weight: 700;
          color: #09427D;
          .green {
            margin: 0;
            color: #47a08b;

            margin-left: -7px;
          }
        }
        small {
          position: absolute;
          left: 57px;
          top: 40px;
          color: #fff;
          font-size: 12px;
          font-weight: bold;
        }
      }
      .links{
          display: flex;
          align-items: center;
          column-gap: 30px;
          justify-content: space-between;

          .link{
              cursor: pointer;
          }
      }
    }
  .white-bg {
    background-color: #fff;
    padding: 30px 250px;
    border-radius: 12px;
    .page-heading {
      font-size: 26px;
      color: #09427d;
      font-weight: 700;
      text-align: center;
    }
    .page-sub-heading {
      color: #363636;
      font-size: 16px;
      margin-bottom: 50px;
      text-align: center;
    }

    .label {
      margin-top: 30px;
      margin-bottom: 5px;
    }

    .dark-btn {
      height: 50px;
      width: 100%;
      border-radius: 5px;
    }
  }
}
