.cash-points {
  border-radius: 10px;
  .top-section {
    display: flex;
    flex-direction: column;

    &.left-col{
      @media only screen and (min-width : 1450px){
        max-width: fit-content !important;
      }
    }
    .points {
      display: inline-block;
      .vpay-card {
        width: 330px;
        height: 190px;
        border-radius: 10px;
        overflow: hidden;
        position: relative;
        display: inline-block;
        font-family: "BW-modelica-medium";
        .bg {
          background-image: url("../../../assets/logoIcon.svg");
          background-repeat: no-repeat;
          background-size: cover;
          background-position-x: 180px;
          filter: grayscale(100%);
          opacity: 0.6;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 2;
          width: 100%;
          height: 190px;
        }
        .overlay {
          opacity: 0.85;
          width: 100%;
          height: 190px;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 3;
        }
        .card-texts {
          width: 100%;
          height: 190px;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 5;
          display: flex;
          flex-direction: column;
          text-align: center;
          text-align: left;
          padding: 20px;
          .data {
            margin-top: 15px;
            .big {
              margin-top: 0;
              margin-bottom: 0;
              color: #fff;
              .top {
                font-size: 12px;
                margin-bottom: 0;
                margin-bottom: -5px;
              }
              .btm {
                font-size: 22px;
                font-weight: 600;
                margin-bottom: 0;
              }
            }
            .small {
              font-size: 12px;
              font-weight: 500;
              color: #fff;
            }
          }
        }
      }
    }
    .add {
    width: 330px;
    height: 50px;
    border: 1px dashed #1a4279;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 7px;
    cursor: pointer;
    margin-top: 15px;
    }
  }
  .white-bg {
    background-color: #fff;
    padding: 20px;
    font-family: "BW-modelica-medium";
    border-radius: 12px;
    overflow: auto;
    // height: 450px;

    .ant-table-wrapper{
      width: 150%;
      max-width: 200%;
    }

  
    .ant-table-thead > tr > th {
      background-color: #fff;
      color: #09427d;
      padding: 14px -1px;
      font-weight: 500;
    }
    .ant-table-tbody > tr > td {
      font-size: 12px;
      padding: -1px;
      font-family: "BW-modelica-medium";
    }
    .ant-table-tbody > tr {
      cursor: pointer;
      &:hover{
        background-color: #01B2E60D;
      }
    }
    // .ant-table-tbody > tr > th{
    //   width: 100%;
    // }
    .chart-heading {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      height: 150px;
      .rev {
        color: #09427d;
        font-size: 16px;
        margin-bottom: 0;
        max-width: 50%;
        line-height: 18px;
      }
      .amt {
        color: #363636;
        font-weight: 700;
        font-size: 32px;
        margin-top: 0px;
      }

      .calendar {
        border: 1px solid #36363659;
        border-radius: 24px;
        padding: 0 10px;
        height: 30px;
        max-width: 160px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        span {
          color: #3636369f;
        }
      }
    }
  }
}

