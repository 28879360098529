.home {
    .nav {
      display: flex;
      height: 90px;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0 170px;
      .logo {
        align-items: center;
        display: flex;
        
        a{
              align-items: center;
             display: flex;
        }
        img {
          width: 50px;
          margin-right: 5px;
        }
        span {
          font-size: 30px;
          font-weight: 700;
          color: #09427D;
          .green {
            margin: 0;
            color: #47a08b;

            margin-left: -7px;
          }
        }
        small {
          position: absolute;
          left: 57px;
          top: 40px;
          color: #fff;
          font-size: 12px;
          font-weight: bold;
        }
      }
      .links{
          display: flex;
          align-items: center;
          column-gap: 30px;
          justify-content: space-between;

          .link{
              cursor: pointer;
          }
      }
    }

    .tc-title{
       display: flex;
       align-items: center;
       background-color: #09427D;
       height: 200px;
       padding: 0px 170px;
       border: 1px solid #707070;

       h2{
           color: #FFFFFF;
           font-family: 'Raleway';
           font-size: 30px;
           font-weight: bold;
       }
    }

    .about-content{
        padding: 50px 170px;
        font-size: 20px;

        a{
            color: black;
            text-decoration: underline;
        }

        p{
            text-align: left;
            font-family: 'Bw Modelica';
            font-size: 20px;
        }
    }
}
