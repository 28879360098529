.txns {

  .wallet_card-wrapper{
    padding: 10px 30px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

     .vpay-card {
    width: 360px;
    height: 160px;
    border-radius: 6px;
    overflow: hidden;
    position: relative;

    .overlay {
      opacity: 0.85;
      width: 360px;
      height: 160px;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 3;
    }
    .card-texts {
      width: 100%;
      height: 200px;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 5;
      display: flex;
      flex-direction: column;
      text-align: center;
      text-align: left;
      padding-top: 20px;
      padding-left: 30px;
      p {
        color: #fff;
        &.small {
          margin-bottom: -5px;
          font-weight: 500;
          font-size: 20px;
          font-family: "BW-modelica-medium";
          margin-bottom: 55px;
        }
        &.big {
          font-size: 25px;
          font-weight: 600;
          margin-top: 0;
          font-family: "BW-modelica-medium";
        }
      }
    }
  }
  }

  .t_w-wrapper{
    display: flex;
    align-items: center;
   

    .bt{
      height: 55px;
      width: 150px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      font-weight: bold;
      font-size: 14px;
      justify-content: center;
      margin-left: 10px;
      cursor: pointer;

      span{
        margin-left: 10px;
        cursor: pointer;
      }

      &.transfer{
        background: rgba(255, 28, 38, 0.07);
        color: #FF0000;
      }
      &.withdraw{
        background: rgba(71, 160, 139, 0.07);
        color: #47A08B;
      }
    }
  }
   
    .white-bg {
      padding: 10px 30px;

      .ant-table{
        overflow: auto;
      }
      
      .ant-table-thead > tr > th {
        background-color: #fff;
       color: rgba(32, 14, 50, 0.5) !important;
        padding: 14px -1px;
        font-weight: normal !important;
      }

      .ant-table-tbody > tr > td {
        font-size: 13px;
        padding: -1px;
        font-family: "BW-modelica-medium" !important;
      }
    .ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td{
      padding: 10px 10px !important;
    }
    }
  }



  