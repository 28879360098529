.dash-home {
  font-family: "BW-modelica-medium";
  .vpay-card-big {
    background-color: #fff;
    width: 100%;
    height: 260px;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    padding: 20px 30px;
    .top {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .left {
        .hello {
          font-size: 30px;
          color: #09427d;
          margin-bottom: 0;
          margin-bottom: 0;
          font-weight: 600;
          .txt {
            font-size: 20px;
            display: block;
            margin-bottom: -15px;
          }
        }
        .url {
          font-size: 12px;
          color:#09427dad;
          text-transform: lowercase;
        }
      }
      .right {
        img {
          width: 60px;
        }
      }
    }
    .gradient {
      width: 100%;
      // height: 90px;
      padding: 12px 20px;
      margin-top: 10px;
      margin-bottom: 10px;
      background-color: #09427d;
      border-radius: 12px;
      cursor: pointer;
      .bg {
        font-size: 16px;
        color: #fff;
        font-weight: 600;
        display: block;
        margin-bottom: -5px;
      }
      .sm {
        font-size: 12px;
        color: #fff;
      }
    }
    .add-merchant {
      background-color: #e6f2ff;
      color: #09427d;
      padding: 10px;
      border-radius: 10px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      cursor: pointer;
    }
  }
  .vpay-card {
    width: 100%;
    height: 170px;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    .bg {
      background-image: url("../../../assets/logoIcon.svg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position-x: 170px;
      filter: grayscale(100%);
      opacity: 0.6;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      width: 100%;
      height: 200px;
    }
    .overlay {
      opacity: 0.85;
      width: 100%;
      height: 200px;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 3;
    }
    .card-texts {
      width: 100%;
      height: 200px;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 5;
      display: flex;
      flex-direction: column;
      text-align: center;
      text-align: left;
      padding-top: 20px;
      padding-left: 30px;
      p {
        color: #fff;
        &.small {
          margin-bottom: -5px;
          font-weight: bold;
          font-size: 20px;
          font-family: "BW-modelica-medium";
          margin-bottom: 55px;
        }
        &.big {
          font-size: 25px;
          font-weight: 600;
          margin-top: 0;
          font-family: "BW-modelica-medium";
        }
      }
    }
  }

  .white-bg {
    background-color: #fff;
    border-radius: 12px;
    width: 100%;
    min-height: 390px;
    margin-top: 30px;
    padding: 20px;
    &.pull-up {
      margin-top: -60px;
      min-height: 480px;
    }
    .chart-heading {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      height: 150px;
      .rev {
        color: #09427D;
        font-size: 16px;
        margin-bottom: 0;
        line-height: 18px;
      }
      .amt {
        color: #09427D;
        font-weight: 700;
        font-size: 32px;
        margin-top: 0px;
      }

      .calendar {
        border: 1px solid #36363659;
        border-radius: 24px;
        padding: 0 10px;
        height: 30px;
        max-width: 160px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        span {
          color: #3636369f;
        }
      }
      .view-all {
        color: #47a08b;
        font-weight: 600;
      }
    }
    .notifications {
      margin-top: -70px;
      height: 270px;
      overflow: auto;
      .notification {
        position: relative;
        margin-bottom: 25px;
        .mail {
          background-color: #47a08b60;
          color: #47a08b;
          width: 50px;
          height: 50px;
          line-height: 50px;
          border-radius: 12px;
          display: inline-block;
          text-align: center;
          font-size: 20px;
          position: absolute;
          top: 0;
        }
        .preview {
          width: calc(100% - 65px);
          margin-left: 65px;
          display: inline-block;
          .title {
            color: #363636;
            font-size: 16px;
            font-weight: 600;
            margin: -5px 0 0px;
          }
          .text {
            color: #888888;
            font-size: 11px;
          }
        }
      }
    }
  }
}
