footer {
  text-align: center;
  padding: 30px;
  p {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
    span {
      padding: 0 20px;
      color: #363636;
      opacity: 0.41;
      font-size: 14px;
      cursor: pointer;
    }
  }
}
