@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@200;300;400;500;600;700;800&display=swap");

// .app {
//   // font-family: "Raleway", sans-serif;
// }

// @font-face {
//   font-family: "BW-modelica-medium";
//   src: url("./assets/fonts/FontsFree-Net-SFProDisplay-Medium.ttf");
// }

// @font-face {
//   font-family: "BW-modelica-medium";
//   src: url("./assets/fonts/FontsFree-Net-SFProDisplay-Regular.ttf");
// }

// font family styles
.raleway {
  font-family: "Raleway", sans-serif !important;
}
.bw-l {
  font-family: BW-modelica-light !important;
}
.bw-r {
  font-family: BW-modelica-regular !important;
}
.bw-m {
  font-family: BW-modelica-medium !important;
}
.bw-b {
  font-family: BW-modelica-bold !important;
}

// buttons
button {
  font-family: "BW-modelica-medium", sans-serif;
  font-weight: 600;
  cursor: pointer;
  &:focus {
    outline: none;
  }
}
.sm-btn {
  height: 36px;
  border-radius: 6px;
  padding: 0 15px;
  font-size: 13px;
  border: 0;
}
.bg-btn {
  height: 45px;
  border-radius: 6px;
  padding: 0 20px;
  font-size: 14px;
  border: 0;
  font-family: "BW-modelica-medium";
}
.green-btn {
  background: #47a08b !important;
  border: 2px solid #47a08b !important;
  color: #fff !important;
}
.yellow-btn {
  background: #f7941f !important;
  border: 2px solid #f7941f !important;
  color: #fff !important;
}

.white-btn {
  background: transparent !important;
  border: 1px solid #fff !important;
  color: #fff !important;
}
.white-btn_ {
  border: 1px solid #eee !important;
  color: #eee !important;
  background-color: #fff !important;
}
.dark-btn {
  border: 1px solid #09427d !important;
  color: #fff !important;
  background-color: #09427d !important;
}
// form fields
.ant-input,
.ant-select {
  border: 1px solid #7070704d !important;
  border-radius: 10px !important;
  background-color: #fff !important;
  &.csp {
    background-color: transparent !important;
    border: 1px solid #e6e6e6 !important;
    border-radius: 50px !important;
    color: #fff !important;
    font-size: 12px !important;
  }
  &.has-error {
    border: 1px solid #ff5252 !important;
  }
}
.ant-input-affix-wrapper {
  border: 1px solid #7070704d !important;
  border-radius: 10px !important;
  background-color: #fff !important;
  padding: 0 10px !important;
  input {
    background-color: transparent !important;
    border: 0 !important;
  }
  &.has-error {
    border: 1px solid #ff5252 !important;
  }
}

.ant-alert-with-description .ant-alert-message {
  text-transform: uppercase !important;
  font-weight: 600 !important;
}

// scrollbar
/* width */
::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d3d3d3;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
