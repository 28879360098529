@font-face {
  src: url("../assets/fonts/BwModelicaCyrillicDEMO-Light.woff");
  font-family: BW-modelica-light;
}
@font-face {
  src: url("../assets/fonts/BwModelicaCyrillicDEMO-Regular.woff");
  font-family: BW-modelica-regular;
}
@font-face {
  src: url("../assets/fonts/BwModelicaCyrillicDEMO-Medium.woff");
  font-family: BW-modelica-medium;
}
@font-face {
  src: url("../assets/fonts/BwModelicaCyrillicDEMO-Bold.woff");
  font-family: BW-modelica-bold;
}
