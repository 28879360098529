.ant-modal-content {
  padding: 0 !important;
  border-radius: 10px;
  // height: 500px;

  .step-wrapper {
    .title-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &.await {
        justify-content: center;
      }
      .header-text {
        font-size: 25px;
        color: #09427d;
        font-weight: 700;
      }
      img {
        height: 65px;
        cursor: pointer;
      }
      img:hover {
        transform: scale(1.5);
      }
    }

    .beneficiary-wrapper {
      max-height: 500px;
      overflow: auto;
    }

    .search-wrapper {
      margin-top: 30px;
      position: relative;
      width: 100%;

      .searchIcon {
        position: absolute;
        top: 10px;
      }

      input {
        border: none;
        border-bottom: 1px solid rgba(0, 0, 0, 0.226);
        width: 100%;
        outline: none;
        height: 40px;
        color: #09427d;
        font-size: 20px;
        padding: 0px 30px;
      }
    }
    .anticon-arrow-left {
      font-size: 20px;
      color: #00000070;
      cursor: pointer;
    }

    .anticon-arrow-left:hover {
      transform: scale(1.2);
    }
    .ant-divider-horizontal {
      margin: 12px 0px;
    }

    .ant-radio-group {
      width: 100%;

      .ant-radio-button-wrapper:first-child {
        border-radius: 10px 0px 0px 10px;
      }
      .ant-radio-button-wrapper:last-child {
        border-radius: 0px 10px 10px 0px;
      }
      .ant-radio-button-wrapper {
        width: 50%;
        height: 55px;
        border: 0.5px solid #47a08b;
        color: #47a08b;
        font-size: 17px;
        font-weight: bold;
        line-height: 50px !important;
        text-align: center;
      }
    }
    .ant-radio-group-solid
      .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      background: #47a08b;
      color: white;
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
      background-color: #47a08b;
    }
    .ant-radio-button-wrapper:hover {
      color: none;
    }

    .d-wrapper {
      margin-top: 25px;

      .ant-select {
        color: #09427d;
        font-size: 17px;
      }

      .ant-input {
        color: #09427d;
        font-size: 17px;
      }

      .label {
        color: rgba(9, 66, 125, 0.3);
        margin: 10px 0px;
      }

      .save-ben {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 30px 0px 20px 0px;
      }
    }
    .confirm_details-wrapper {
      width: 100%;
      background: #47a08b;
      border: 0.5px solid #47a08b;
      box-sizing: border-box;
      border-radius: 10px;
      padding: 20px;
      color: white;

      .details {
        font-size: 20px;
        font-weight: 700;

        span {
          font-weight: 500;
        }
      }

      .ant-divider {
        color: white;
      }
      .ant-divider-inner-text {
        padding: 0;
      }
      .ant-divider-horizontal.ant-divider-with-text-left::before {
        top: 0;
        width: 0;
      }
    }
  }
}
