
.home {
  .ak{
    display: flex;
    justify-content: center;
  .hero {
    position: relative;
    text-align: center;
    padding: 2px 12px;
    margin-bottom: 6px;
    width: 100%;
    height: auto;
    min-height: 900px;
    min-width: 0;
    max-width: 1600px;
    background-image: url("../../assets/hero.png");
    background-size: 100% auto;
    background-repeat: no-repeat;

    @media only screen and(min-width: 1700px){
       margin: auto 64px;
    }
    .nav {
      display: flex;
      height: 90px;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0 50px  0 170px;
      .logo {
        align-items: center;
        position: relative;
        img {
          width: 50px;
          margin-right: 5px;
        }
        span {
          font-size: 30px;
          font-weight: 700;
          color: #fff;
          .green {
            margin: 0;
            color: #47a08b;

            margin-left: -7px;
          }
        }
        small {
          position: absolute;
          left: 57px;
          top: 40px;
          color: #fff;
          font-size: 12px;
          font-weight: bold;
        }
      }
      .links {
        .link {
          display: inline-block;
          margin-left: 30px;
          font-size: 13px;
          cursor: pointer;
          color: #09427D;
          font-weight: bolder;
          font-family: "BW-modelica-medium";
        }
      }
    }
    .hero-texts {
      width: 60%;
      text-align: left;
      padding: 80px 0 60px 170px;
      h1 {
        font-size: 64px;
        line-height: 74px;
        color: #fff;
        font-weight: 700;
        margin-bottom: 12px;
        font-family: "BW-modelica-medium";
      }
      p {
        font-size: 18px;
        color: #fff;
        margin-bottom: 30px;
        font-family: "BW-modelica-medium";
      }

      .white-btn {
        margin-left: 20px;
      }
    }
  }
}
  .gains {
    padding: 00px 170px 30px;
    width: 100%;
    min-width: 0;
    max-width: 1500px;

   @media only screen and(min-width: 1700px){
       margin: auto 64px;
    }
    .purple--text {
      color: #09427D;
      font-size: 24px;
      line-height: 30px;
      font-weight: 700;
      width: 100%;
      text-align: center;
      font-family: "BW-modelica-medium";
      margin-bottom: 2em;

      @media only screen and (min-width: 1500px){
        margin-top: 100px;
      }
 
    }
    .gain-sect {
    display: flex;
    flex-direction: column;
    align-items: center;
      img {
        height: 60px;
        margin-top: 20px;
      }
      .sect-title {
        font-size: 20px;
        font-weight: 600;
        color: #09427D;
        margin-bottom: 12px;
        margin-top: 12px;
        text-align: center;
        font-family: "BW-modelica-medium";
      }
      .sect-desc {
        font-size: 14px;
        color: #888888;
        margin-top: 0;
        text-align: center;
      }
    }
  }

  .info-grid {
    padding: 80px 70px 80px 170px;
    // width: 100%;
    min-width: 0;
    max-width: 1500px;
 
     .temp-row{
      justify-content: space-between;
      align-items: center;
      width: calc(90vw - 270px);
      max-width: 1300px;
     }

    .lady {
      width: 80%;
    }
    .purple--text {
      font-size: 22px;
      line-height: 30px;
      font-weight: 700;
      width: 200px;
      // margin-top: 190px;
    }
    .info-desc {
      font-size: 14px;
      color: #888888;
      margin-top: 0;
    }
    .rating-desc {
      font-family: 'BW-modelica-medium';
    }
    .who {
      font-size: 16px;
      color: #363636;
    }
    .ratings {
      margin: 40px 0 10px;
    }
    .images {
      position: relative;
      height: 320px;
      margin-top: 0px;
      .el8 {
        position: absolute;
        top: 12px;
        left: 150px;
        width: 20px;
      }
      .el7 {
        position: absolute;
        top: 180px;
        left: 80px;
        width: 35px;
        z-index: 5;
      }
      .el6 {
        position: absolute;
        top: 140px;
        left: 340px;
        width: 20px;
        z-index: 5;
      }
      .sm1 {
        position: absolute;
        top: 0;
        left: 30px;
        width: 80px;
      }
      .sm2 {
        position: absolute;
        top: 0;
        left: 320px;
        width: 80px;
      }
      .sm3 {
        position: absolute;
        bottom: 0px;
        left: 0;
        width: 80px;
      }
      .sm4 {
        position: absolute;
        bottom: 0;
        left: 350px;
        width: 120px;
      }
      .bg1 {
        position: absolute;
        top: 50px;
        left: 100px;
        width: 250px;
      }
    }
  }

  .pilot-section {
    background: #fafafa;
    padding: 30px 170px;
    text-align: center;
    p {
      margin: 0;
      span {
        color: #363636;
      }
      img {
        margin-left: 10px;
        height: 30px;
      }
    }
  }

  .fees {
    height: 400px;
    width: 100%;
    background-image: url("../../assets/fees.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h2 {
      font-weight: 700;
      color: #fff;
      font-size: 40px;
      margin-bottom: 5px;
      text-align: center;
    }
    h4 {
      color: #fff;
      font-size: 18px;
      margin-bottom: 20px;
    }
  }

  .ready {
    padding: 100px 100px 120px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    .purple--text {
      font-size: 42px;
      font-weight: 700;
      margin-bottom: 0;
    }
    .ready-desc {
      font-size: 16px;
      color: #363636;
      width: 600px;
      text-align: center;
      margin: 10px 0;
    }
  }

  .purple--text {
    color: #09427d;
  }

  @media only screen and (max-width: 768px) {
    .hero {
      background-image: url("../../assets/hero_.png");
      .nav {
        padding: 0;
        height: 70px;
        .logo {
          img {
            width: 40px;
          }
          span {
            color: #09427d;
            font-size: 20px !important;
          }
        }
        .links {
          display: none;
        }
      }
      .hero-texts {
        width: 100%;
        padding: 30px;
        margin-top: 240px;
        text-align: center;
        h1 {
          font-size: 36px;
          line-height: 40px;
          text-align: center;
        }
        p {
          text-align: center;
        }
        a {
          display: block;
          button {
            width: 70%;
            margin: 10px 15% !important;
          }
        }
      }
    }
    .gains {
      padding: 0 20px;
      .gain-sect {
        text-align: center;
        .sect-desc {
          width: 80%;
          margin-left: 10%;
          text-align: center;
        }
      }
    }
    .info-grid {
      padding: 0 20px;
      .lady {
        margin-left: 10%;
      }
      .images {
        width: 100%;
        overflow: hidden;
        img {
          position: relative !important;
        }
        .sm1 {
          top: 0;
          left: -5%;
        }
        .sm2 {
          left: 37%;
        }
        .sm3 {
          top: 168px;
          left: -209px;
          width: 80px;
        }
        .sm4 {
          bottom: -50px;
          left: 70%;
          width: 80px;
        }
        .el6 {
          width: 10px;
        }
        .bg1 {
          position: relative;
          width: 180px;
          top: -24px;
          left: 10px;
        }
      }
      .purple--text {
        margin-top: 30px;
        width: 80%;
        margin-left: 10%;
        text-align: center;
      }
      .info-desc {
        width: 80%;
        margin-left: 10%;
        text-align: center;
      }

      .rating-desc {
        width: 80%;
        margin-left: 10%;
        text-align: center;
      }
      .ratings {
        text-align: center;
      }
      .who {
        text-align: center;
      }
      .arrow-icn {
        display: none;
      }
    }
    .pilot-section {
      padding: 30px 20px;
    }
    .fees {
      h2 {
        width: 100%;
        text-align: center;
        line-height: 44px;
        margin-bottom: 20px;
      }
    }
    .ready {
      padding: 30px 20px;
      .ready-desc {
        width: 100%;
      }
    }
  }
}

