.auth {
  background: #f8f8f8;
  min-height: 100vh;
  padding-bottom: 40px;
  font-family: "BW-modelica-medium";

  


  .nav {
    height: 90px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 170px;
    // a {
    .logo-wrap {
      font-size: 30px;
      font-weight: 700;
      position: relative;
      .green {
        margin: 0;
        color: #47a08b;

        margin-left: -7px;
      }

      small {
        position: absolute;
        left: 43px;
        top: 35px;
        color: #888585;
        font-size: 12px;
      }
    }
    // }
    img {
      height: 34px;
    }
    a span {
      color: #47a08b;
    }
    a .bw-b {
      color: #09427d;
    }
  }

  @media only screen and (max-width: 768px) {
    .nav {
      padding: 0 20px;
      background-color: #fff;
      box-shadow: 0px 3px 6px #0000000f;
      height: 70px;
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 1000897;
      .logo-wrap {
        font-size: 20px;
        small {
          position: absolute;
          left: 40px;
          top: 24px;
          color: #888585;
          font-size: 8px;
          width: 203px;
        }
      }
      .already {
        display: none;
      }
    }
  }
}

.form-content {
  max-width: 700px;
  margin: 0px auto;

  .has-error {
    border: 1px solid #ff5252 !important;
  }
  .form-title {
    color: #09427d;
    font-size: 22px;
    text-align: center;
    margin-bottom: 5px;
    font-weight: 700;
  }
  .form-subtitle {
    font-size: 16px;
    color: #363636;
    text-align: center;
    margin-bottom: 55px;
  }
  .label {
    color: #363636;
    font-size: 14px;
    margin-bottom: 5px;
    margin-top: 15px;

  }
  .rq{

    &::after{
      content: "*";
      color: red;
      font-size: 2em;
      position: absolute;
      top: 10px;
      
    }
  }
  .caveat {
    color: #363636;
    opacity: 0.68;
    font-size: 14px;
  }
  .bars {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 12px;
    margin-bottom: 12px;
    .bar {
      width: 19%;
      background-color: #ebebeb;
      border-radius: 4px;
      height: 4px;
      &.filled {
        background-color: #47a08b;
      }
    }
  }
  .hide {
    visibility: hidden;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    padding: 0 30px;
    .form-title {
      padding-top: 120px;
    }
  }
}
