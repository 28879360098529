.support {
  .white-bg {
    background-color: #fff;
    padding: 10px 20px;
    border-radius: 12px;
    .page-heading {
      font-size: 26px;
      color: #09427d;
      font-weight: 700;
      text-align: center;
    }
    .page-sub-heading {
      color: #363636;
      font-size: 16px;
      margin-bottom: 10px;
      text-align: center;
    }

    .label {
      margin-top: 30px;
      margin-bottom: 5px;
      color: #09427d;
    }

    .dark-btn {
      height: 50px;
      width: 150px;
      border-radius: 5px;
    }
  }
}
