.sidebar {
  height: 100vh;
  text-align: left;
  position: relative;
  z-index: 22;
  border-right: 1px solid #e2e7ee;
  transition: linear all 0.2s;
  .top {
    height: 80px;
    padding: 0 25px;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    img {
      height: 30px;
      margin-left: -10px;
      margin-right: 5px;
    }
    span {
      font-size: 30px;
      font-weight: 700;
      color: #09427d;
      display: flex;
      column-gap: 8px;
      .green {
        margin: 0;
        color: #47a08b;

        margin-left: -7px;
      }
    }
    .caret {
      width: 15px;
      height: 15px;
      border-radius: 15px;
      background-color: #09427d;
      color: #fff;
      position: absolute;
      top: 34px;
      right: -7.5px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  // nav-links

  ul.links {
    padding: 0;
    margin: 50px 0;
    height: 55vh;
    overflow: auto;
    li {
      font-size: 13px;
      color: #36363659;
      // text-overflow: ellipsis;
      transition: linear all 0.1s;
      cursor: pointer;
      .main-link {
        padding: 4px 20px;
        margin: 12px 0;
        white-space: nowrap;
        overflow: hidden;
        border-left: 3px solid transparent;
        display: flex;
        flex-direction: row;
        align-items: center;
        span {
          display: inline-block;
          width: 35px;
          svg {
            width: 18px !important;
            position: relative;
            top: 4px;
            fill: #36363659 !important;
            stroke: #3636360f !important;
            // opacity: 0.3 !important;
          }
        }
        &.active-link {
          color: #363636;
          border-left: 3px solid #47a08b;
          span {
            svg {
              fill:#09427d6e !important;
              stroke: #09427d6e !important;
              //   opacity: 1 !important;
            }
          }
        }
      }
      .inner {
        height: 0;
        width: 100%;
        overflow: hidden;
        transition: linear all 0.3s;
        &.open-inner {
          height: 145px;
        }
      }
      img {
        margin-right: 30px;
        height: 12px;
      }
      .inner-link {
        padding: 15px 0 15px 40px;
        background-color: #400003;
        border-bottom: 0.5px solid #4a0002ee;
        margin: 0;
        &.active-inner-link {
          color: #ffcb05;
        }
        .circle {
          width: 8px;
          height: 8px;
          border: 1px solid #ffcb05;
          border-radius: 50%;
          margin-right: 30px;
          display: inline-block;
        }
      }
    }
  }
  .logout {
    color: rgb(0, 0, 0);
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    bottom: 40px;
   
  }
  .info-cont{
    padding: 0px 10px;
    width: 100%;
    margin-bottom: 30px;

    .fee-info{
      padding: 15px;
      background: linear-gradient(125.05deg, #47A08B 2.19%, #09427D 102.97%);
      border-radius: 5px;
      font-weight: bold;
      font-size: 17px;
      color: white;
      position: absolute;
      bottom: 120px;
  
    }
  }
  @media (max-width: 768px), (min-width: 768px) and (max-width: 1024px) {
    display: none;
    .logout {
      display: block;
    }
  }
}
