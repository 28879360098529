.dashboard {
  background-color: #f8f8f8;
  display: flex;
  flex-direction: row;
  .dash-body {
    height: 100vh;
    width: 100%;
    display: inline-block;
    .dashboard-heading {
      height: 90px;
      padding: 20px 30px;
      width: 100%;
      .page-title {
        font-size: 30px;
      }
      .inp {
        position: relative;
        width: 80%;
        padding-top: 10px;
        input {
          width: 100%;
          border: 0;
          background-color: #fff;
          border-radius: 40px;
          height: 40px;
          padding-left: 55px;
          position: absolute;
          left: 0;
          z-index: 10;
          &:focus {
            outline: none;
          }
        }
        span {
          position: absolute;
          left: 12px;
          top: 19px;
          z-index: 12;
          padding-right: 12px;
          border-right: 1px solid #dfdfdf;
          img {
            width: 18px;
          }
        }
      }
      .notif {
        width: 40px;
        height: 40px;
        border-radius: 40px;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        float: right;
        img {
          width: 15px;
        }
      }
    }
    .dash-contents {
      padding: 30px;
      padding-bottom: 20px;
      height: calc(100vh - 90px);
      overflow: auto;
    }
  }
}
